import { Coordinate } from "./_coordinate";

export class HouseFinder {
  constructor(){
    this.num = document.getElementById("number");
    this.street = document.getElementById("street");
    this.latitudeElement = document.getElementById("latitude");
    this.longitudeElement = document.getElementById("longitude");
    this._currentLatitude = null;
    this._currentLongitude = null;
    this.currentStreet = null;
    this.addressMatcher = /([\d-]+[a-z]?) ([^,]+)/i;
    this.counter = 0;
    this.logger = document.getElementById("logger");
    this._requestStatus = null;
  }

  start() {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(this.newPosition.bind(this), null, {enableHighAccuracy: true});
    } else {
      this.street.innerHTML = "Geolocation is not supported by this browser.";
    }

    this.latitudeElement.addEventListener("click", function(){
      this.logger.style.display = "block";
    }.bind(this))
  }

  newPosition(position){
    let newPosition = new Coordinate(position.coords.latitude, position.coords.longitude);

    this.latitudeElement.innerHTML = newPosition.latitude.toFixed(5); // 5 decimal places = 1 metre accuracy
    this.longitudeElement.innerHTML = newPosition.longitude.toFixed(5); // http://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude/8674

    if(this.currentPosition && !this.currentPosition.isSignificantlyDifferent(newPosition)){
      this.logc(".");
      return;
    }

    this.currentPosition = newPosition;

    if(this.requestStatus==null){
      this.requestNearbyAddress();
    }
    else {
      this.requestStatus = "REQUEST PENDING";
    }
  }

  requestNearbyAddress(){
    let xhr = new XMLHttpRequest();
    xhr.open('GET', `https://api.addressfinder.io/api/nz/address/nearby?key=${javascriptConfig.ADDRESSFINDER_API_KEY}&format=json&x=` +
      this.currentPosition.longitude +
      '&y=' + this.currentPosition.latitude + '&max=2');
    xhr.addEventListener("load", this.processAddressFinderNearbyResult.bind(this));
    xhr.addEventListener("error", this.processAddressFinderNearbyError.bind(this));
    xhr.addEventListener("abort", this.processAddressFinderNearbyAbort.bind(this));
    xhr.send();
    this.requestStatus = "SENT";
  }

  processAddressFinderNearbyError(event){
    this.log("Error response from Addressfinder: " + event);
    if(this.requestStatus=="REQUEST PENDING"){
      this.requestNearbyAddress();
    }
    else {
      this.requestStatus = null;
    }
  }

  processAddressFinderNearbyAbort(event){
    this.log("Abort response from Addressfinder: " + event);
    if(this.requestStatus=="REQUEST PENDING"){
      this.requestNearbyAddress();
    }
    else {
      this.requestStatus = null;
    }
  }

  processAddressFinderNearbyResult(event){
    if(this.requestStatus=="REQUEST PENDING"){
      this.requestNearbyAddress();
    }
    else {
      this.requestStatus = null;
    }

    let data = JSON.parse(event.target.response);

    if(data.completions.length == 0){
      this.log("No nearby addresses found");
      return;
    }

    let fullAddress = data.completions[0].a;
    let matches = fullAddress.match(this.addressMatcher);

    this.log("Address1: " + fullAddress);

    if(matches==null){
      this.log("Regex could not match address: " + fullAddress);
      return;
    }

    let firstStreetNumber = matches[1];
    let firstStreetName = matches[2];

    if(this.currentStreet == null || firstStreetName == this.currentStreet){
      this.num.innerHTML = firstStreetNumber;
      this.street.innerHTML = firstStreetName;
      this.currentStreet = firstStreetName;

      this.log("first match");

      return;
    }

    fullAddress = data.completions[1].a;
    matches = fullAddress.match(this.addressMatcher);

    this.log("Address2: " + fullAddress);

    if(matches==null){
      this.log("Regex could not match address: " + fullAddress);
      return;
    }

    let secondStreetNumber = matches[1];
    let secondStreetName = matches[2];

    if(secondStreetName == this.currentStreet){
      this.log("Using second match as first match was a different street - " + firstStreetName);
      this.num.innerHTML = secondStreetNumber;
      this.street.innerHTML = secondStreetName;
      return;
    }
    else {
      // use the first match
      this.log("first match, as second wasn't " + this.currentStreet);
      this.num.innerHTML = firstStreetNumber;
      this.street.innerHTML = firstStreetName;
      this.currentStreet = firstStreetName;
    }
  }

  log(message){
    if(window.console){
      console.log(message);
    }

    this.counter = this.counter + 1;
    this.logger.innerHTML = this.counter + ": " + message + "\n" + this.logger.innerHTML;
  }

  logc(message){
    if(window.console){
      console.log(message);
    }

    this.logger.innerHTML = message + this.logger.innerHTML;
  }

  set requestStatus(newStatus){
    this._requestStatus = newStatus;
    this.log("Request status: " + newStatus);
  }

  get requestStatus(){
    return this._requestStatus;
  }

  get currentPosition(){
    return this._currentPosition;
  }

  set currentPosition(newPosition){
    this._currentPosition = newPosition
  }
}
