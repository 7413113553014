export class Coordinate {
  constructor(latitude, longitude) {
    this._latitude = latitude;
    this._longitude = longitude;
  }

  get latitude(){
    return this._latitude;
  }

  get longitude(){
    return this._longitude;
  }

  get combinedValue(){
    return Math.abs(this._latitude) + Math.abs(this._longitude);
  }

  isSignificantlyDifferent(otherCoordinate){
    let diff = Math.abs(this.combinedValue - otherCoordinate.combinedValue)

    return diff > 0.00003 // about 3.3m
  }
}
